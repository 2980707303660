<script>
  export let margin;
</script>
<hr style={`margin-top: ${margin} `} />

<style>
   hr {
    background: rgba(128, 128, 128, 0.219);
    height: 0.09rem;
    border: none;
    margin: unset;
  }
</style>